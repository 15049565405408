/***************************************Fonts*******************************************/

@font-face {
    font-family: 'Segoe UI';
    src: url(./assets/webfonts/segoeui.ttf);
}

@font-face {
    font-family: 'Segoe UI Bold';
    src: url(./assets/webfonts/SegoeUIBold.ttf);
}

@font-face {
    font-family: 'Segoe UI Samibold';
    src: url(./assets/webfonts/SegoeUI-SemiBold.ttf);
}

.segoe-regular {
    font-family: Segoe UI
}

.segoe-bold {
    font-family: 'Segoe UI Bold';
}

.segoe-samibold {
    font-family: 'Segoe UI Samibold';
}

/***************************************Colors*******************************************/

.primary {
    color: #5C6FFF;
}

.primary-bg {
    background-color: #5C6FFF;
}

.primary-border {
    border: 1px solid #5C6FFF80;
}

.red-bg {
    background-color: #FF5555;
}

.red {
    color: #FF5555;
}

.border-red {
    border: 1px solid #FF5555 !important;
}

.grey-bg {
    background-color: #FCFDFF;
}

.grey {
    color: #ABAEB4;
}

.secondary {
    color: #3C3F4A;
}

.offwhite-bg {
    background-color: #F3F5F9;
}

.footer-offwhite-bg {
    background-color: #EBEEF5;
}

.yellow-bg {
    background: #FFB45C;
}

.yellow {
    color: #FFB45C;
}

.green-bg {
    background: #2BC277;
}

.red-hover:hover {
    color: var(--red);
}

:root {
    --primary: #5C6FFF;
    --red: #FF5555;
    --grey: #ABAEB4;
    --secondary: #3C3F4A;
    --offwhite-bg: #F3F5F9;
}

.ratio {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    /* % of width, defines aspect ratio*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

/***************************************Radius*******************************************/

.radius-6 {
    border-radius: 6px;
}

.radius-11 {
    border-radius: 11px;
}

.radius-16 {
    border-radius: 16px;
}

.radius-right-11 {
    border-radius: 11px 0 0 11px;
}

.radius-left-11 {
    border-radius: 0 11px 11px 0;
}


.radius-right-6 {
  border-radius: 6px 0 0 6px !important;
}

.radius-left-11 {
  border-radius: 0 6px 6px 0 !important;
}

.radius-bottom-16 {
    border-radius: 0px 0px 16px 16px;
}

.radius-top-16 {
    border-radius: 16px 16px 0px 0px;
    ;
}

.radius-50 {
    border-radius: 50px !important
}

/***************************************General*******************************************/

body {
    font-family: 'Segoe UI';
}

a, i, span, .primary-bg {
    transition: all .3s ease-in-out;
}

p:not(.none-fonted), a:not(.bottom-bar .nav-link) {
    font-size: .8em;
}

a:hover, .dropdown-item:focus, .dropdown-item:hover {
    color: var(--primary);
}

a:focus {
    outline: none;
    box-shadow: none;
}

:focus {
    outline: none;
    box-shadow: none;
}

a:hover:not(.primary-bg) i {
    color: var(--primary);
}

.vertical-separetor {
    height: 18px;
    width: 1px;
    background-color: var(--grey);
    position: relative;
    opacity: .5;
    top: 12px;
    display: inline-block;
    margin: 0 0 0 .8em;
}

i {
    z-index: 5;
}

.title-separator {
    height: 3px;
    width: 50px;
    background: var(--red);
    display: block;
}

.pointer {
    cursor: pointer;
}

.object-fit-scaledown {
    object-fit: scale-down;
}

@media(min-width:1200px) {
    .w-lg-75 {
        width: 81% !important;
    }
    .fixed-lg-top {
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        z-index: 1030;
    }
    .fs-1-3 {
        font-size: 1.2em;
    }
}

@media(min-width:767px) and (max-width:1199px) {
    .w-lg-75 {
        width: 78% !important;
    }
}

.loader {
    position: absolute;
    border: 3px solid #f3f3f3;
    border-radius: 80%;
    border-top: 3px solid var(--primary);
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sav-from-nav {
    margin-top: 255px;
}

/***************************************Navbar*******************************************/

/****************Counties select2 override*************/

.top-bar .select2-container--default .select2-selection--single:not(.select2-container--open) {
    border-radius: 50px;
    width: 100px;
    border: 0 !important;
}

.top-bar .selection, .select2-selection.select2-selection--single {
    outline: none !important;
    background-color: transparent !important;
}

.top-bar .select2-results__option--selectable {
    background: transparent;
}

.top-bar .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: -9px;
    /*-------------------------------- TODO:: AR->*/
}

.navbar-nav .nav-link {
    font-size: .9em;
}

input[type=search]::placeholder {
    color: var(--primary);
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom-left-radius: 50px !important;
    /*-------------------------------- TODO:: AR->*/
    border-bottom-right-radius: 50px !important;
    /*-------------------------------- TODO:: AR->*/
}

/****************Navbar*************/

.navbar-brand {
    width: 20%;
}

.navbar-brand img {
    width: 90%;
}

.search-icon {
    left: 8px;
}

.middle-bar input::placeholder {
    color: var(--grey);
    padding-left: 10px;
}

/*-------------------------------- TODO:: AR->*/

.bottom-bar li.active a {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
    padding-bottom: 0;
}

/****************Mega Menu*************/

.mega-dropdown {
    position: unset;
}

@media(min-width:992px) {
    .dropdown .dropdown-menu.mega-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: all .3s ease-in;
        -o-transition: all .3s ease-in;
        transition: all .3s ease-in
    }
    .dropdown:hover>.dropdown-menu.mega-menu {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
    }
    .mega-dropdown-menu {
        width: 70% !important;
        position: absolute;
        top: 30px;
        margin: 15px 0px;
    }
}

.country-icon {
    width: 14px;
}

@media(max-width:767px) {
    .mega-dropdown-menu {
        width: 100%;
        position: absolute;
        top: 30px;
        margin: 15px 0px;
    }
}

/****************Mega Menu*************/

@media(max-width:767px) {
    .navbar-brand {
        width: 70%;
    }
    .navbar-brand img {
        width: 80%;
    }
    .bottom-bar li.active a {
        color: var(--primary);
        border-bottom: 0px solid var(--primary);
        padding-bottom: 0;
    }
}

/***************************************Home Slider*******************************************/

#slider .owl-dots {
    display: flex;
    justify-content: center;
}

#slider img, .banner img {
    height: 400px;
    object-fit: fill;
}

#slider .owl-dot span {
    background: var(--primary);
    width: 30px;
    height: 5px;
    margin: 0 3px;
    opacity: .3;
    position: relative;
    bottom: 25px;
}

.owl-dot.active span {
    opacity: 1 !important;
}

.category-overlay {
    display: none;
}

.category-overlay:hover {
    display: block;
}

#slider .owl-carousel button.owl-dot.active {
    opacity: 1;
    outline: none;
    z-index: 5;
}

.shop-now-slider-position {
    top: 70%;
    right: 5%;
    font-size: .9em;
}

.btn-vertical-separetor {
    height: 29px;
    width: 1px;
    background-color: var(--primary);
    opacity: 0.5;
    position: absolute;
    top: 0px;
    display: inline-block;
}

.shop-now-slider-position:hover {
    background-color: var(--primary) !important;
    color: var(--offwhite-bg);
}

.shop-now-slider-position:hover i {
    color: var(--offwhite-bg)!important;
}

.shop-now-slider-position:hover .btn-vertical-separetor {
    background-color: var(--offwhite-bg)!important;
}

@media(max-width:767px) {
    #slider img {
        height: 300px;
        object-fit: fill;
    }
}

/***************************************Home Slider*******************************************/

/***************************************Home Category*******************************************/

@media(min-width:1200px) {
    .category-image {
        height: 208px;
    }
}

@media(max-width:767px) {
    .category-image {
        height: 150px;
    }
}

.category-overlay {
    background: var(--primary);
    opacity: .9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/***************************************Home Category*******************************************/

/***************************************Home Products*******************************************/

.product-image {
    height: 300px;
    object-fit: fill;
    width: 100%;
}

.new-label {
    top: 10px;
    left: 10px
}

/*-------------------------------- TODO:: AR->*/

.marks-label-container {
    bottom: 8px;
    left: 8px
}

/*-------------------------------- TODO:: AR->*/

.label-size {
    font-size: 12px;
}

.new-label img {
    width: 22px !important;
}

.product-item span {
    font-size: 12px;
}

.line-through {
    text-decoration: line-through;
}

.product-fav-icon {
    bottom: 8px;
    right: 10px;
}

.product-fav-icon i {
    font-size: 1.2em;
}

/***************************************Home Products*******************************************/

/***************************************home delivery*******************************************/

.free-delivery .basic-icon-size {
    width: 40px;
    height: 40px;
    object-fit: fill;
}

.free-delivery .img-container {
    width: 70px;
    height: 70px;
}

/***************************************home delivery*******************************************/

/***************************************home newsletter*******************************************/

.newsletter button {
    right: 0;
    /*-------------------------------- TODO:: AR->*/
}

.newsletter input, .newsletter input:focus {
    background: transparent;
    color: var(--offwhite-bg);
    border-color: #80bdff;
}

.newsletter input::placeholder {
    color: var(--offwhite-bg) !important;
    opacity: 1;
}

/***************************************home newsletter*******************************************/

/***************************************home newsletter*******************************************/

.vertical-align-middle {
    vertical-align: middle !important;
}

/***************************************home newsletter*******************************************/

/***************************************shop Page*******************************************/

.search-select, .search-select:focus {
    border: 1px solid var(--primary) !important;
    color: var(--primary);
}

.price-height {
    height: 30px;
}

.h-35 {
    height: 35px;
}

.fs-9 {
    font-size: .9em;
}

/***************************************shop Page*******************************************/

/***************************************About page*******************************************/

.lh-2 {
    line-height: 2;
}

/***************************************About page*******************************************/

/***************************************Product page*******************************************/

.fs-8 {
    font-size: .8em;
}

#product .cover-image {
    height: 500px;
    object-fit: fill;
    width: 100%;
}

#product .attachment-image {
    height: 93.3px;
    object-fit: cover;
    width: 100%;
}

#product .attachment-holder:not(.active) .overflow {
    background: rgba(0, 0, 0, .6);
    width: 100%;
    z-index: 50;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    cursor: pointer;
}

#product .attachment-holder:not(.active) {
    cursor: pointer;
}

#product .attachment-holder.active {
    border: 1px solid var(--primary);
}

#product .product-label {
    font-size: .8em;
}

.product-label-internal {
    font-size: .6em;
}

#product .shop-now-slider-position {
    top: 82%;
    right: 10%;
}

.border-primary {
    border: 1px solid var(--primary);
}

#productDescription li.active {
    border-bottom: 2px solid var(--primary);
}

#productDescription .Tabs {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

#productDescription .Tabs:after {
    content: ' ';
    display: table;
    clear: both;
}

#productDescription .description_tab {
    float: left;
    text-align: center;
}

#productDescription .description_tab:nth-child(2).active~.slider, #productDescription .description_tab {
    left: 7.333%;
}

#productDescription .slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 8.333%;
    height: 2px;
    /* background-color: var(--primary); */
    transition: left .25s;
}

#productDescription .Tab>a {
    display: block;
    padding: 10px 12px;
    text-decoration: none;
    transition: color .15s;
}

@media(max-width:767px) {
    #productDescription .description_tab:nth-child(2).active~.slider, #productDescription .description_tab {
        left: 25%;
    }
    #productDescription .slider {
        width: 25%;
    }
    #product .cover-image {
        height: 350px;
    }
}

/***************************************Product page*******************************************/

.footer a:hover {
    text-decoration: none;
}
